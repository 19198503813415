<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col md="3" sm="12" lg="6" class="d-flex align-items-center">
          <h4 class="mr-sm-4 header-tablepage">
            ACTIVITY LOG
          </h4>
        </b-col>
        <b-col md="9" sm="12" lg="6" class="text-right d-flex">
          <b-input-group>
            <b-form-input
              type="search"
              class="search-bar form-control"
              @keyup.enter="handleSearch"
              placeholder="Search Name-Surname, IP Address"
              v-model="filter.search"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
          <b-button @click.prevent="openFilter" class="button-extra ml-2">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="main-color mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">Filter </span>
          </b-button>
          <b-button class="button-extra ml-2" @click.prevent="exportExcel">
            <span class="d-none d-sm-block text-nowrap">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </b-button>
        </b-col>
      </b-row>
      <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
        <TableActivityLog
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :pageOptions="pageOptions"
          :showingTo="showingTo"
          :showing="showing"
          :rows="rows"
          :filter="filter"
          @page="changePage"
          @getPageOption="getPageOption"
        />
        <FilterActivityLog
          ref="openFilterLog"
          :filter="filter"
          :activityList="activityList"
          @submitFilter="confirmSubmit"
          @search="handleSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableActivityLog from "@/components/activitylog/TableActivityLog";
import FilterActivityLog from "@/components/activitylog/FilterActivityLog";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    TableActivityLog,
    FilterActivityLog,
    OtherLoading,
  },
  data() {
    return {
      rows: 0,
      items: [],
      fields: [
        {
          label: "Date/Time",
          key: "created_time",
        },
        {
          label: "Name-Surname",
          key: "display_name",
        },
        {
          label: "IP Address",
          key: "ip_address",
        },
        {
          label: "Activity",
          key: "activity",
        },
        {
          label: "Target",
          key: "target",
        },
        {
          label: "Detail",
          key: "detail",
        },
      ],
      isBusy: true,
      isLoading: true,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      filter: {
        activity_type: [],
        search: "",
        start_date: null,
        end_date: null,
        page: 1,
        take: 10,
      },
      activityList: [],
      filterExport: {
        activity_type: [],
        search: null,
        start_date: null,
        end_date: null,
      },
    };
  },
  async created() {
    await this.getActivityLog();
    await this.getaActivityType();
  },
  methods: {
    async getaActivityType() {
      await this.$store.dispatch("getListActivityType");
      const data = this.$store.state.activitylog.stateActivityType;
      if (data.result === 1) {
        this.activityList = data.detail;
        this.isLoading = false;
      }
    },
    async getActivityLog() {
      await this.$store.dispatch("getActivityLog", this.filter);
      const data = this.$store.state.activitylog.stateActivityLog;
      if (data.result === 1) {
        this.items = data.detail.data;
        this.isBusy = false;
        this.rows = data.detail.total_count;
        this.showingTo = this.items?.length >= 10 ? 10 : this.items.length;
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    openFilter() {
      this.$refs.openFilterLog.show(true);
    },
    handleSearch() {
      this.getActivityLog();
    },
    getPageOption(value) {
      this.isBusy = true;
      this.filter = value;
      this.getActivityLog();
    },
    changePage(value) {
      this.isBusy = true;
      this.filter = value;
      this.getActivityLog();
    },
    async exportExcel() {
      this.isLoading = true;
      this.filterExport.activity_type = this.filter.activity_type;
      this.filterExport.search = this.filter.search;
      this.filterExport.start_date = this.filter.start_date;
      this.filterExport.end_date = this.filter.end_date;
      await this.$store.dispatch("exportActivityLog", this.filterExport);
      let data = this.$store.state.activitylog.stateExportActivityLog;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `ActivityLog-` + dateExcel + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.isLoading = false;
    },
    confirmSubmit(value) {
      this.filter = value;
      this.getActivityLog();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-gray {
  color: #ababab;
}
.btn-link {
  color: #000;
}
.button-extra {
  width: 250px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.button-extra-fill {
  width: 120px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.input-container {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.form-control {
  height: 100%;
}
</style>
